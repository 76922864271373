/* Font */
@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700');

body,
html {
    overflow-x: hidden;
}

body {
    color: #272727;
    font-family: 'Quicksand', serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
    padding: 0rem;
}
.sec-title{
    position:relative;
    z-index: 1;
    margin-bottom:60px;
  }
  
  .sec-title .title{
    position: relative;
    display: block;
    font-size: 18px;
    line-height: 24px;
    color: #00aeef;
    font-weight: 500;
    margin-bottom: 15px;
  }
  
  .sec-title h2{
    position: relative;
    display: block;
    font-size:40px;
    line-height: 1.28em;
    color: #222222;
    font-weight: 600;
    padding-bottom:18px;
  }
  
  .sec-title h2:before{
    position:absolute;
    content:'';
    left:0px;
    bottom:0px;
    width:50px;
    height:3px;
    background-color:#d1d2d6;
  }
  
  .sec-title .text{
    position: relative;
    font-size: 16px;
    line-height: 26px;
    color: #848484;
    font-weight: 400;
    margin-top: 35px;
  }
  
  .sec-title.light h2{
    color: #ffffff;
  }
  
  .sec-title.text-center h2:before{
    left:50%;
    margin-left: -25px;
  }
  
  .list-style-one{
    position:relative;
  }
  
  .list-style-one li{
    position:relative;
    font-size:16px;
    line-height:26px;
    color: #222222;
    font-weight:400;
    padding-left:35px;
    margin-bottom: 12px;
  }
  
  .list-style-one li:before {
      content: "\f058";
      position: absolute;
      left: 0;
      top: 0px;
      display: block;
      font-size: 18px;
      padding: 0px;
      color: #ff2222;
      font-weight: 600;
      -moz-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1.6;
      font-family: "Font Awesome 5 Free";
  }
  
  .list-style-one li a:hover{
    color: #44bce2;
  }
  
  .btn-style-one{
    position: relative;
    display: inline-block;
    font-size: 17px;
    line-height: 30px;
    color: #ffffff;
    padding: 10px 30px;
    font-weight: 600;
    overflow: hidden;
    letter-spacing: 0.02em;
    background-color: #00aeef;
  }
  
  .btn-style-one:hover{
    background-color: #0794c9;
    color: #ffffff;
  }
  .about-section{
    position: relative;
    padding: 0;
  }
  
  .about-section .sec-title{
    margin-bottom: 45px;
  }
  
  .about-section .content-column{
    position: relative;
  }
  
  .about-section .content-column .inner-column{
    position: relative;
    padding: 30px;
    text-align: justify;
  }
  
  .about-section .text{
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 26px;
    color: #848484;
    font-weight: 400;
  }
  
  .about-section .list-style-one{
    margin-bottom: 45px;
  }
  
  .about-section .btn-box{
    position: relative;
  }
  
  .about-section .btn-box a{
    padding: 15px 50px;
  }
  
  .about-section .image-column{
    position: relative;
  }
  
  .about-section .image-column .text-layer{
      position: absolute;
      right: -110px;
      top: 50%;
      font-size: 325px;
      line-height: 1em;
      color: #ffffff;
      margin-top: -175px;
      font-weight: 500;
  }
  
  .about-section .image-column .inner-column{
    position: relative;
    padding-left: 80px;
    padding-bottom: 0px;
  }
  

  .container-fluid {
    margin-top: 50px;
  }
  
   .aboutUsImg{
     border-radius: 50%;
     height: auto;
   }
  .team {
    width: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
  }
  
  .team .member {
    width: 48%;
    margin: 5px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 0px 14px 3px rgb(0 0 0 / 7%);
    padding: 25px;
  }
  
  @media only screen and (max-width: 600px) {
    .team .member {
      width: 90%;
    }
  }

  .team .member .aboutUsImg {
    width: 80px;
  }
  
  .team .member h3 {
    color: #444;
  }
  
  .team .member span {
    font-size: 12px;
    color: #999;
  }
  
  .team .member p {
    margin: 15px 0;
    font-weight: 400;
    color: #999;
    font-size: 18px;
    text-align: justify;
  }