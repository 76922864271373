/* Font */
@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700');

/* Design */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  background-color: #ecf9ff;
}

body {
  color: #272727;
  font-family: 'Quicksand', serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  padding: 0rem;
}

.main{
  /* max-width: 1200px; */
  margin: 0 auto;
}

h1 {
    font-size: 26px;
    font-weight: 400;
    text-align: center;
}

img {
  height: 100%;
  max-width: 100%;
  vertical-align: middle;
}

.btn {
  color: #ffffff;
  padding: 0.8rem;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 400;
  display: block;
  width: 100%;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
}

.btn:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards_item {
  display: flex;
  padding: 1rem;
}

@media (min-width: 40rem) {
  .cards_item {
    width: 50%;
  }
}

@media (min-width: 56rem) {
  .cards_item {
    width: 25%;
  }
}

.card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 20px -24px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 0;
}

.card_content {
  padding: 0.5rem;
  background: white;
}

.card_title {
  color: #727271;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0px;
  text-align: center;
}

.card_text {
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;    
  font-weight: 400;
}
.made_by{
  font-weight: 400;
  font-size: 13px;
  margin-top: 35px;
  text-align: center;
}

.logo{
    height: 9rem;
    width: 100%;
}

.card_image{
  width: 100%;
  height: -webkit-fill-available;
}

/* https://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, main {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* -------------------------------- 

Primary style

-------------------------------- */
*, *::after, *::before {
  box-sizing: border-box;
}

body {
  /* font-size: 1.6rem;
  font-family: "Open Sans", sans-serif; */
  color: #3e454c;
  background-color: #ffffff;
}
body::after {
  clear: both;
  content: "";
  display: table;
}

a {
  color: #1784c7;
  text-decoration: none;
}

.cd-side-nav {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0;
  background-color: #727271;
  visibility: hidden;
  opacity: 0;
  max-height: 100vh;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: opacity 0.2s 0s, visibility 0s 0.2s;
  -moz-transition: opacity 0.2s 0s, visibility 0s 0.2s;
  transition: opacity 0.2s 0s, visibility 0s 0.2s;
}
.cd-side-nav.nav-is-visible {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: opacity 0.2s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.2s 0s, visibility 0s 0s;
  transition: opacity 0.2s 0s, visibility 0s 0s;
  max-height: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.cd-side-nav > ul {
  padding: 0.6em 0;
}
.cd-side-nav > ul:last-of-type {
  padding-bottom: 0;
}
.cd-side-nav .cd-label, .cd-side-nav a {
  display: block;
  padding: 1em 5%;
}
.cd-side-nav .cd-label {
  text-transform: uppercase;
  font-weight: bold;
  color: #646a6f;
  font-size: 1rem;
  letter-spacing: .1em;
}
.cd-side-nav a {
  position: relative;
  color: #ffffff;
  font-size: 1.4rem;
}
/* .cd-side-nav ul.cd-top-nav > li:last-of-type > a {
  border-bottom: none;
} */
.cd-side-nav > ul > li > a {
  padding-left: calc(5% + 24px);
  border-bottom: 1px solid #ffffff !important;
}
.cd-side-nav > ul > li > a::before {
  /* icon before item name */
  position: absolute;
  content: '';
  left: 5%;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
  background: url(https://codyhouse.co/demo/responsive-sidebar-navigation/img/cd-nav-icons.svg) no-repeat 0 0;
}
.cd-side-nav > ul > li.overview > a::before {
  background-position: -64px 0;
}
.cd-side-nav > ul > li.notifications > a::before {
  background-position: -80px 0;
}
.cd-side-nav > ul > li.comments > a::before {
  background-position: -48px 0;
}
.cd-side-nav > ul > li.bookmarks > a::before {
  background-position: -32px 0;
}
.cd-side-nav > ul > li.images > a::before {
  background-position: 0 0;
}
.cd-side-nav > ul > li.users > a::before {
  background-position: -16px 0;
}
.cd-side-nav .count {
  /* notification badge */
  position: absolute;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: calc(5% + 16px + 0.4em);
  padding: 0.2em 0.4em;
  background-color: #ff7e66;
  border-radius: .25em;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}
.cd-side-nav .action-btn a {
  display: block;
  margin: 0 5%;
  padding: 1em 0;
  background-color: #1784c7;
  border-radius: .25em;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.2);
  text-align: center;
  color: #ffffff;
  font-weight: bold;
}
.cd-side-nav .action-btn a::before {
  display: none;
}
@media only screen and (min-width: 768px) {
  .cd-side-nav {
    position: relative;
    float: left;
    top: auto;
    width: 110px;
    min-height: 100vh;
    /* padding-top: 55px; */
    /* reset style */
    visibility: visible;
    opacity: 1;
    overflow: visible;
    max-height: none;
  }
  .cd-side-nav.nav-is-visible {
    box-shadow: none;
  }
  .cd-side-nav.is-fixed {
    position: fixed;
  }
  .cd-side-nav > ul {
    /* reset style */
    padding: 0;
  }
  .cd-side-nav .cd-label {
    /* remove labels on minified version of the sidebar */
    display: none;
  }
  .cd-side-nav a {
    font-size: 1.2rem;
    text-align: center;
  }
  .cd-side-nav > ul > li > a {
    padding: calc(0.2em + 3px) 0 0.2em;
  }
  .cd-side-nav > ul > li > a::before {
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 2.4em;
  }
  .cd-side-nav .active > a {
    /* current page */
    box-shadow: inset 3px 0 0 #1784c7;
    background-color: #33383e;
  }
  .cd-side-nav .action-btn a {
    margin: 1em 10% 0;
  }
  .cd-side-nav .count {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 0;
    top: 2em;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    left: calc(50% + 5px);
    right: auto;
    color: transparent;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-side-nav {
    width: 200px;
  }
  .cd-side-nav > ul {
    padding: 0.6em 0;
  }
  .cd-side-nav > ul > li:not(.action-btn):hover > a {
    background-color: #33383e;
  }
  .cd-side-nav > ul > li > a {
    padding: 1em 1em 1em 42px;
    text-align: left;
    border-bottom: none;
  }
  .cd-side-nav > ul > li > a::before {
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 18px;
  }
  .cd-side-nav .cd-label {
    display: block;
    padding: 1em 18px;
  }
  .cd-side-nav .action-btn {
    text-align: left;
  }
  .cd-side-nav .action-btn a {
    margin: 0 18px;
  }
  .no-touch .cd-side-nav .action-btn a:hover {
    background-color: #1a93de;
  }
  .cd-side-nav .count {
    /* reset style */
    color: #ffffff;
    height: auto;
    width: auto;
    border-radius: .25em;
    padding: .2em .4em;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 18px;
    left: auto;
    box-shadow: none;
  }
}

@media (max-width: 576px) { 
  .mobileViewCaption{
    display: block !important;
  }
}